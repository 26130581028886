import * as React from 'react';
import { connect } from 'react-redux';
import YoutubeEmbed from "./YoutubeEmbedded";
import Hero from './hero';
import HeroFooter from './heroFooter';
import Mint from './Mint';
import DropLinks from './dropLinks';
import Redeem from './Redeem';
import AboutTheProject from './AboutTheProject';
import Faq from 'react-faq-component';

const faqData = {
    title: "FAQ",
    rows: [
        {
            title: "What is Wavelength?",
            content: "Wavelength is an evolving code I�ve been adapting for the last few months. The idea stemmed from our brain's electrical activity and how a thought can spark millions of connections within our brain. Creating the bridge between code and our brain's functionality is a fascinating idea which relates on an extremely relative scale. The random processes our brains execute without conscious thought relate to the way code writes without the need for complete physical interaction. Once the foundation of the code/ thought is outlined, the rest progresses like a subconscious thought. <br/><br/>"
        },
        {
            title: "How many pieces are there in the collection?",
            content: "1111 <br/><br/>"
        },        
        {
            title: "Is the project on chain?",
            content: "Your piece is generated depending on an expanded seed number which is randomly generated with Chainlink VRF, this means that no one can influence the random number, not even us. So once people mint, we run the code and your seed number created by the contact will result in the output of your piece.<br/><br/> (More info about Chainlink VRF can be found here: https://docs.chain.link/docs/chainlink-vrf/) <br/><br/> The art is rendered off chain, but the output is decided on chain.<br/><br/>The art is rendered off chain, but the images generated will be stored on the Arweave block chain(onchain).<br/><br/> (More info about Arweave can be found here: https://arweave.org/) <br/><br/> Generating these onchain would be ridiculously expensive due to how complex the code is and certain libraries we use.But the source code will be hosted on Arweave block chain too and be accessible from the contract along with your seed number, meaning the info behind what created your piece will be forever stored on chain, along with the png image displaying your piece.In addition, the code is accessible from the contract, owners will be able to download processing, input their seed number and run the code to output their exact piece. Open Source code. <br/><br/> For those who don�t know what this means, it means the stored data isn�t located on a website where if hosting fees were to stop being paid / the website went down, you�d lose the data to your NFT(viewing it and more).Having the art on the Arweave blockchain means it relies on no domains and is stored on the blockchain accessible and viewable by you, for essentially ever(long after you�re gone).This is very important for the future of NFTs and an approach we wanted to ensure we took as much as possible. <br/><br/>"

        },
        {
            title: "Is Wavelength made with AI?",
            content: "No, AI (GAN) - is a machine learning model in which two neural networks compete with each other to become more accurate in their predictions, essentially creating art for you at its core.Wavelength, is hand written code where each sentence builds what the artist wants.The code isn't written for you (like it is in GAN) It's like using photoshop, tools there are coded to do specific things.There's no tools in generative art, you have to code every tool yourself essentially. <br/><br/>"
        },
        {
            title: "Will there be prints for collectors?",
            content: "We have partnered with supplier that accepts crypto as payment and verifies the NFT is in your wallet before you are able to purchase the print, along with this you get a letter of verification showcasing you are the true owner.Size of the prints and type of the prints are customizable, however we will likely only lock in 2 options(or even 1) to ensure variation across prints isn�t too large. <br/><br/>"
        }]
}


const styles = {
    bgColor: 'transparent',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
    width: "100%",

    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    tabFocus: true
};

const Home = () => (

    <div className="centralMainPage">

        <div className="heroBanner">

            <h1 className="heroTitle">
                WAVELENGTH
            </h1>

            <h2 className="heroDescription">
                AN ONCHAIN GENERATIVE ART PROJECT OF 1111 PIECES
            </h2>
            <br />
            <br />
            <br />
            <br />
            <h3 className="byKaleb">
                BY KALEB JOHNSTON
            </h3>
        </div>

        <div className="infoSection">

            <div className="infoLeftCollumn">
                <img src="images/Wavelength/wavelengthtile.png" className="sideImage"/>
            </div>
            <div className="infoRightCollumn">
                <AboutTheProject/>
            </div>
        </div>


        <DropLinks />

        <img src="images/Wavelength/banner.png" className="bannerImage"/>
        


        <div className="theTeam">

            <h1>The TEAM</h1>

            <div className="teamCollumnLeft">
                <img src="images/Wavelength/Kaleb.png" className="teamImage" />
                <h2><a href="https://twitter.com/KalebsCode">@KalebsCode</a></h2>
                Kaleb Johnston is a New Zealand based photographer and generative artist creating abstract art via code. Fascinated with the idea of bridging the gap between AI and Humans by simulating the cognitive abilities of the human brain.
            </div>

            <div className="teamCollumnRight">
                <img src="images/Wavelength/Fatlion.png" className="teamImage" />
                <h2><a href="https://www.twitter.com/georgefatlion">@georgefatlion</a></h2>
                Fatlion/George is a London based creative coder, digital maker and music producer. By day he develops robots and works as a technician at UCL's Institute of Making. By night he supports a range of NFT projects and hunts for hidden gems on the block chain.
            </div>

            <h3 className="centerText"> Join the Wavelength community on discord!</h3>
            <a href="https://discord.gg/w7ZvnKfrvQ"><img src="images/Wavelength/discord.png" className="discordLogo" /></a>
        </div>
        <div className = "faqWrapper">
        <Faq data={faqData} styles={styles}
                config={config} />
        </div>
    </div>
);

export default connect() (Home);


//{
//    title: "Timeline for the mint:",
//        content: "27th Nov 7am - 7pm Eastern - Whitelist mint is live<br/><br/>27th Nov 3pm Eastern - Speed raffle opens. <br/> <br/> 28th Nov 7am Eastern - Public Mint goes live.<br/><br/>We will then begin generating the art from the token seeds and upload it as soon as it is ready. We hope this will be within 48 hours. <br/><br/>"
//},

//<Mint />
