import * as React from 'react';
import { connect } from 'react-redux';

const Home = () => (

    <div className="dropsLinkTableDiv">
        <h1 className="aboutTitle"> Drops </h1>

        <table className="linkTable">

            <tr>
                <img src="images/wavelength.jpg" className="linkImage"></img>


                <td><b>Wavelength</b></td>
                <td>Nov 2021</td>

                <td><a className="" href="https://opensea.io/collection/wavelengthbykalebjohnston" target="none">Opensea</a></td>
                <td><a className="" href="https://etherscan.io/address/0x68930072647a0d79e601d5ee3914833ae9148247#code" target="none">ERC721 Contract</a></td>
                {/*<td ><img className="linkImage" src="jpegs/wavelength.jpg" /></td>*/}


            </tr>
            <br />
            <tr>
                <img src="https://lh3.googleusercontent.com/ZW1SlUQRPJ-SfeAlKD5BeUBM9Di1AG7yXCDPBxroMYSblKyFWFRpRe11zd4QXNZV2igqTRUIVBe52SItUUXorPZvz4AqfZ81ByOfPA=w600" className=" linkImage"></img>


                <td><b>Kaleb's Special Editions</b></td>
                <td>Jan 2022</td>


                <td><a className="" href="https://opensea.io/collection/kalebs-editions" target="none">Opensea </a></td>
                <td><a className="" href="https://etherscan.io/address/0x814350140f16a44fe71a7fad4bfc613f64407320#code" target="none">ERC1155 Contract</a></td>


            </tr>
            <br />

            <tr>
                <img src="https://lh3.googleusercontent.com/VrMxIU3iE38e2SlTOkKJp-SjmyEUj_oMcu-sNsjxl30tfwWcjRakG38YBNLYlfzNnXdQaTb4yb68LmuSBCTI3eHvoG9ZC6jGH7lI=w600" className=" linkImage"></img>

                <td><b>mfer x wavelength</b></td>
                <td>Feb 2022</td>


                <td><a className="" href="https://opensea.io/collection/wavelengthxmfers" target="none">Opensea </a></td>
                <td><a className="" href="https://etherscan.io/address/0xaec4ea20cff1897cdf8b7be329b0e2d1d49b9817#code" target="none">ERC1155 Contract</a></td>

            </tr>
            <br />

            <tr>
                <img src="https://lh3.googleusercontent.com/6EtmQ5jFJaLiREga06L3f6e2LAxc8ELFiKwWtG3uDBPFoNXIflblvWpjcOkQGZmLTgFdZ6bhUTAoo_HwQ3hJEIkSWfHpMVSIjTZXkQ=w600" className=" linkImage"></img>

                <td><b>Orbs</b></td>
                <td>May 2022</td>


                <td><a className="" href="https://opensea.io/collection/orbsbykaleb" target="none">Opensea </a></td>
                <td><a className="" href="https://etherscan.io/address/0x0e53451832875db6a19934c5adf8d34286956a52#code" target="none">ERC721A Contract</a></td>


            </tr>
        </table>
    </div>
);

export default connect() (Home);


//{
//    title: "Timeline for the mint:",
//        content: "27th Nov 7am - 7pm Eastern - Whitelist mint is live<br/><br/>27th Nov 3pm Eastern - Speed raffle opens. <br/> <br/> 28th Nov 7am Eastern - Public Mint goes live.<br/><br/>We will then begin generating the art from the token seeds and upload it as soon as it is ready. We hope this will be within 48 hours. <br/><br/>"
//},

//<Faq data={faqData} styles={styles}
//    config={config} />