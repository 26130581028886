import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const styles = {
    global: props => ({
        body: {
            color: mode('white', 'whiteAlpha.900')(props),
            bg: mode('#66798c', '#66798c')(props),
            //bg: mode('#000000', '#000000')(props),
            fonts: {
                heading: 'Bebas Neue',
                body: 'Bebas Neue',
            },
        },
    }),
};

const components = {
    Drawer: {
        // setup light/dark mode component defaults
        baseStyle: props => ({
            dialog: {
                bg: mode('white', '#141214')(props),
            },
        }),
    },
};

const theme = extendTheme({
    components,
    styles,
});

export default theme;