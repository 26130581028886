import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import LayoutB from './components/LayoutB';
import Home from './components/Home';
import Editions from './components/Editions';
import Mint from './components/Mint';
import Orbs from './components/OrbMint';
import Check from './components/OrbCheck';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Footer from './components/SocialComponent';
import Journal from './components/journal';


import theme from "./theme";
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";

import './custom.css'


function App() {

    return (
        <ChakraProvider theme={theme}>

                <Layout>
                    <Route exact path='/' component={Home} />
                    {/*<Route exact path='/orbsclaim' component={Orbs} />*/}
                    {/*<Route exact path='/orbsclaim/' component={Orbs} />*/}
                    {/*<Route exact path='/orbscheck' component={Check} />*/}
                    {/*<Route exact path='/orbscheck/' component={Check} />*/}
                </Layout>

        </ChakraProvider >


    );
}



export default App;
                    //<Route exact path='/editions' component={Editions} />
