import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { MMButton } from './MMButton';
import WalletStuffTop from './WalletStuffTop';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (

            <header>
                <Navbar sticky={'top'} position= "fixed" className="navBar" light>
                    <div className="linkIcons">
                        <a href="https://twitter.com/KalebsCode">
                        <img className="navbarlink" src="images/Wavelength/twitterIconBlack.png" />
                    </a>
                        <a href="https://discord.gg/w7ZvnKfrvQ/">
                        <img className="navbarlink" src="images/Wavelength/discordIconBlack.png" />
                    </a>

                    <a href="https://opensea.io/collection/wavelengthbykalebjohnston">
                        <img className="navbarlink" src="images/Wavelength/openSeaIconBlack.png" />
                        </a>

                      
                        </div>
                </Navbar>

            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });


    }
}

//<WalletStuffTop />

//  <a className="navLink" href="https://www.wavelengthbykaleb.com/orbscheck/">
//Orb Checker
//</a>

//<a className="navLink" href="https://www.wavelengthbykaleb.com/orbsclaim/">
//    Orb Claim
//</a>
